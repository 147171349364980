import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {DropResponse, DropShopDto} from "../api/grailzApi";

type TDropState = {
  data: DropResponse[];
  drop: DropResponse;
  currentIndex: number;
};

export const initialDropState: DropResponse = {
  id: "",
  title: "",
  description: "",
  price: "",
  resellPrice: "",
  currency: "PLN",
  publishedDate: "",
  startDate: "",
  confirmedStartDate: false,
  expiredDate: "",
  imageUrl: "",
  referralLink: "",
  priority: 0,
  sku: "",
  colorWay: "",
  shops: [],
  glow: false,
  enabled: false
};

const initialState: TDropState = {
  data: [],
  drop: initialDropState,
  currentIndex: 0
};

export const dropsSlice = createSlice({
  name: "DROP",
  initialState,
  reducers: {
    setDropData: <Property extends keyof TDropState>(
      state: TDropState,
      {
        payload,
      }: PayloadAction<{ property: Property; value: TDropState[Property] }>
    ) => {
      const { property, value } = payload;
      state[property] = value;
    },
    setDropProperty: <Property extends keyof DropResponse>(
      state: TDropState,
      {
        payload,
      }: PayloadAction<{ property: Property; value: DropResponse[Property] }>
    ) => {
      const { property, value } = payload;
      state.drop[property] = value;
    },
    setDropShopProperty: <Property extends keyof DropShopDto>(
        state: TDropState,
        {
          payload,
        }: PayloadAction<{ property: Property; value: DropShopDto[Property], index: number }>
    ) => {
      const { property, value, index } = payload;
      if (state.drop.shops !== undefined) {
        state.drop.shops[index][property] = value;
      }
    },
    removeDropShop: (
        state: TDropState,
        {
          payload,
        }: PayloadAction<{ index: number }>
    ) => {
      const { index } = payload;
      if (state.drop.shops !== undefined) {
        state.drop.shops.splice(index, 1);
        state.currentIndex = state.drop.shops?.length-1;
      }
    },
    addDropShop: (
        state: TDropState
    ) => {
      state.drop.shops = state.drop?.shops !== undefined ? state.drop?.shops : [];
      state.drop.shops = state.drop.shops?.concat({date: "", imageUrl: "", url: ""})
      state.currentIndex = state.drop.shops?.length-1;
    },
  },
});

export const { setDropData, setDropProperty, setDropShopProperty, addDropShop, removeDropShop } = dropsSlice.actions;

export const selectDropState = (state: any) => state.drops;

export default dropsSlice.reducer;
