import React, { FC } from "react";
import TableWrapper from "../../components/table/TableWrapper";
import dropActionTemplate from "./Actions";
import {DropResponse, useFindAllDropsQuery} from "../../api/grailzApi";
import { Button } from "primereact/button";
import { routes } from "../../app/routes";
import { useNavigate } from "react-router-dom";
import {Image} from "primereact/image";

const columns = [
  { field: "title", header: "Tytuł" },
  { field: "sku", header: "SKU" },
  { field: "colorWay", header: "Color way" },
  {
    field: "enabled",
    header: "Włączone w aplikacji",
    body: (row: DropResponse) => row.enabled ? "Tak" : "Nie",
    sortable: true
  },
  {
    field: "imageUrl",
    header: "Podgląd",
    body: (row: DropResponse) => <Image src={row.imageUrl} width="100" />,
  },
  { field: "", header: "", body: dropActionTemplate },
];

const Drops: FC<{}> = ({}) => {
  const navigate = useNavigate();
  const { isLoading, data } = useFindAllDropsQuery();

  const addButton = (
    <Button onClick={() => navigate(routes.ADD)} label="Dodaj" />
  );

  return (
    <>
      <TableWrapper
        header="Dropy"
        isLoading={isLoading}
        data={data}
        columns={columns}
        addButton={addButton}
      />
    </>
  );
};

export default Drops;
