import {api} from "./api";
import {setBannerData} from "../slices/bannersSlice";
import {setDropData} from "../slices/dropsSlice";
import {setProductData} from "../slices/productsSlice";
import {cacheTags} from "./cacheTags";
import {setWtbData} from "../slices/wtbsSlice";
import {setWalletData} from "../slices/walletsSlice";
import {setSettingData} from "../slices/settingsSlice";
import {setPromotionData} from "../slices/promotionsSlice";

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        findWtbById: build.query<FindWtbByIdApiResponse, FindWtbByIdApiArg>({
            query: (queryArg) => ({url: `/wtbs/${queryArg.id}`}),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    await dispatch(setWtbData({property: "wtb", value: data}));
                } catch (error) {
                    console.error(error);
                }
            },
        }),
        editWtb: build.mutation<EditWtbApiResponse, EditWtbApiArg>({
            query: (queryArg) => ({
                url: `/wtbs/${queryArg.id}`,
                method: "PUT",
                body: queryArg.wtbRequest,
            }),
            invalidatesTags: [cacheTags.WTBS],
        }),
        deleteWtb: build.mutation<DeleteWtbApiResponse, DeleteWtbApiArg>({
            query: (queryArg) => ({url: `/wtbs/${queryArg.id}`, method: "DELETE"}),
            invalidatesTags: [cacheTags.WTBS],
        }),
        getAllWtbs: build.query<GetAllWtbsApiResponse, GetAllWtbsApiArg>({
            query: () => ({url: `/wtbs`}),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: cacheTags.WTBS, id})),
                        cacheTags.WTBS,
                    ]
                    : [cacheTags.WTBS],
        }),
        createWtb: build.mutation<CreateWtbApiResponse, CreateWtbApiArg>({
            query: (queryArg) => ({
                url: `/wtbs`,
                method: "POST",
                body: queryArg.wtbRequest,
            }),
            invalidatesTags: [cacheTags.WTBS],
        }),
        findDropById: build.query<FindDropByIdApiResponse, FindDropByIdApiArg>({
            query: (queryArg) => ({url: `/drops/${queryArg.id}`}),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    await dispatch(setDropData({property: "drop", value: data}));
                } catch (error) {
                    console.error(error);
                }
            },
        }),
        editDrop: build.mutation<EditDropApiResponse, EditDropApiArg>({
            query: (queryArg) => ({
                url: `/drops/${queryArg.id}`,
                method: "PUT",
                body: queryArg.dropRequest,
            }),
        }),
        disableDrop: build.mutation<DisableDropApiResponse, DisableDropApiArg>({
            query: (queryArg) => ({url: `/drops/${queryArg.id}`, method: "DELETE"}),
            invalidatesTags: [cacheTags.DROPS],
        }),
        findBannerById: build.query<
            FindBannerByIdApiResponse,
            FindBannerByIdApiArg
        >({
            query: (queryArg) => ({url: `/banners/${queryArg.id}`}),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    await dispatch(setBannerData({property: "banner", value: data}));
                } catch (error) {
                    console.error(error);
                }
            },
        }),
        editBanner: build.mutation<EditBannerApiResponse, EditBannerApiArg>({
            query: (queryArg) => ({
                url: `/banners/${queryArg.id}`,
                method: "PUT",
                body: queryArg.bannerRequest,
            }),
        }),
        deleteBanner: build.mutation<DeleteBannerApiResponse, DeleteBannerApiArg>({
            query: (queryArg) => ({
                url: `/banners/${queryArg.id}`,
                method: "DELETE",
            }),
            invalidatesTags: [cacheTags.BANNERS],
        }),
        findAllWalletProducts: build.query<
            FindAllWalletProductsApiResponse,
            FindAllWalletProductsApiArg
        >({
            query: (queryArg) => ({
                url: `/wallet-products`,
                params: {
                    queryText: queryArg.queryText,
                    page: queryArg.page,
                    size: queryArg.size,
                    sort: queryArg.sort,
                },
            }),
            providesTags: (result) =>
                result?.content
                    ? [
                        ...result?.content.map(({id}) => ({type: cacheTags.WALLETS, id})),
                        cacheTags.WALLETS,
                    ]
                    : [cacheTags.WALLETS],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    await dispatch(
                        setWalletData({
                            property: "totalSize",
                            value: !!data.totalElements ? data.totalElements : 0,
                        })
                    );
                    await dispatch(
                        setWalletData({
                            property: "offset",
                            value: !!data?.pageable?.offset ? data?.pageable?.offset : 0,
                        })
                    );
                } catch (error) {
                    console.error(error);
                }
            },
        }),
        sendProposalAboutWalletProduct: build.mutation<
            SendProposalAboutWalletProductApiResponse,
            SendProposalAboutWalletProductApiArg
        >({
            query: (queryArg) => ({
                url: `/wallet-product-commands/${queryArg.id}/send-proposal`,
                method: "POST",
            }),
            invalidatesTags: [cacheTags.WALLETS],
        }),
        findAllProducts: build.query<
            FindAllProductsApiResponse,
            FindAllProductsApiArg
        >({
            query: (queryArg) => ({
                url: `/products`,
                params: {
                    queryText: queryArg.queryText,
                    page: queryArg.page,
                    size: queryArg.size,
                    sort: queryArg.sort,
                },
            }),
            providesTags: result =>
                result?.content
                    ? [...result.content.map(({id}) => ({type: cacheTags.PRODUCTS, id})), cacheTags.PRODUCTS]
                    : [cacheTags.PRODUCTS],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    await dispatch(
                        setProductData({
                            property: "totalSize",
                            value: !!data.totalElements ? data.totalElements : 0,
                        })
                    );
                    await dispatch(
                        setProductData({
                            property: "offset",
                            value: !!data?.pageable?.offset ? data?.pageable?.offset : 0,
                        })
                    );
                } catch (error) {
                    console.error(error);
                }
            },
        }),
        createProduct: build.mutation<
            CreateProductApiResponse,
            CreateProductApiArg
        >({
            query: (queryArg) => ({
                url: `/products`,
                method: "POST",
                body: queryArg.createProductRequest,
            }),
            invalidatesTags: [cacheTags.PRODUCTS]
        }),
        sendCustomNotification: build.mutation<
            SendCustomNotificationApiResponse,
            SendCustomNotificationApiArg
        >({
            query: (queryArg) => ({
                url: `/notification-sender/custom`,
                method: "POST",
                body: queryArg.customNotificationRequest,
            }),
        }),
        findAllDrops: build.query<FindAllDropsApiResponse, FindAllDropsApiArg>({
            query: () => ({url: `/drops`}),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: cacheTags.DROPS, id})),
                        cacheTags.DROPS,
                    ]
                    : [cacheTags.DROPS],
        }),
        createDrop: build.mutation<CreateDropApiResponse, CreateDropApiArg>({
            query: (queryArg) => ({
                url: `/drops`,
                method: "POST",
                body: queryArg.dropRequest,
            }),
        }),
        getAllBanners: build.query<GetAllBannersApiResponse, GetAllBannersApiArg>({
            query: (queryArg) => ({
                url: `/banners`,
                params: {genderType: queryArg.genderType},
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: cacheTags.BANNERS, id})),
                        cacheTags.BANNERS,
                    ]
                    : [cacheTags.BANNERS],
        }),
        createBanner: build.mutation<CreateBannerApiResponse, CreateBannerApiArg>({
            query: (queryArg) => ({
                url: `/banners`,
                method: "POST",
                body: queryArg.bannerRequest,
            }),
        }),
        findProductById: build.query<
            FindProductByIdApiResponse,
            FindProductByIdApiArg
        >({
            query: (queryArg) => ({
                url: `/products/${queryArg.id}`,
                params: {variantId: queryArg.variantId},
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    await dispatch(setProductData({property: "product", value: data}));
                } catch (error) {
                    console.error(error);
                }
            },
        }),
        deleteProduct: build.mutation<
            DeleteProductApiResponse,
            DeleteProductApiArg
        >({
            query: (queryArg) => ({
                url: `/products/${queryArg.id}`,
                method: "DELETE",
            }),
        }),
        findPromotionById: build.query<
            FindPromotionByIdApiResponse,
            FindPromotionByIdApiArg
        >({
            query: (queryArg) => ({url: `/promotions/${queryArg.id}`}),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    dispatch(setPromotionData({property: "promotion", value: data}));
                } catch (error) {
                    console.error(error);
                }
            },
        }),
        editPromotion: build.mutation<
            EditPromotionApiResponse,
            EditPromotionApiArg
        >({
            query: (queryArg) => ({
                url: `/promotions/${queryArg.id}`,
                method: "PUT",
                body: queryArg.promotionRequest,
            }),
            invalidatesTags: [cacheTags.PROMOTIONS],
        }),
        deletePromotion: build.mutation<
            DeletePromotionApiResponse,
            DeletePromotionApiArg
        >({
            query: (queryArg) => ({
                url: `/promotions/${queryArg.id}`,
                method: "DELETE",
            }),
            invalidatesTags: [cacheTags.PROMOTIONS],
        }),
        findPromotionGroupCategoryById: build.query<
            FindPromotionGroupCategoryByIdApiResponse,
            FindPromotionGroupCategoryByIdApiArg
        >({
            query: (queryArg) => ({
                url: `/dictionaries/promotion-group-categories/${queryArg.id}`,
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    dispatch(setSettingData({property: "promotionGroupCategory", value: data}));
                } catch (error) {
                    console.error(error);
                }
            },
        }),
        updatePromotionGroupCategory: build.mutation<
            UpdatePromotionGroupCategoryApiResponse,
            UpdatePromotionGroupCategoryApiArg
        >({
            query: (queryArg) => ({
                url: `/dictionaries/promotion-group-categories/${queryArg.id}`,
                method: "PUT",
                body: queryArg.groupCategoryDto,
            }),
            invalidatesTags: [cacheTags.PROMOTION_GROUP_CATEGORIES],
        }),
        deletePromotionGroupCategory: build.mutation<
            DeletePromotionGroupCategoryApiResponse,
            DeletePromotionGroupCategoryApiArg
        >({
            query: (queryArg) => ({
                url: `/dictionaries/promotion-group-categories/${queryArg.id}`,
                method: "DELETE",
            }),
            invalidatesTags: [cacheTags.PROMOTION_GROUP_CATEGORIES],
        }),
        findPromotionCategoryById: build.query<
            FindPromotionCategoryByIdApiResponse,
            FindPromotionCategoryByIdApiArg
        >({
            query: (queryArg) => ({
                url: `/dictionaries/promotion-categories/${queryArg.id}`,
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    dispatch(setSettingData({property: "promotionCategory", value: data}));
                } catch (error) {
                    console.error(error);
                }
            },
        }),
        updatePromotionCategory: build.mutation<
            UpdatePromotionCategoryApiResponse,
            UpdatePromotionCategoryApiArg
        >({
            query: (queryArg) => ({
                url: `/dictionaries/promotion-categories/${queryArg.id}`,
                method: "PUT",
                body: queryArg.categoryDto,
            }),
            invalidatesTags: [cacheTags.PROMOTION_CATEGORIES],
        }),
        deletePromotionCategory: build.mutation<
            DeletePromotionCategoryApiResponse,
            DeletePromotionCategoryApiArg
        >({
            query: (queryArg) => ({
                url: `/dictionaries/promotion-categories/${queryArg.id}`,
                method: "DELETE",
            }),
            invalidatesTags: [cacheTags.PROMOTION_CATEGORIES],
        }),
        findPromotionBrandById: build.query<
            FindPromotionBrandByIdApiResponse,
            FindPromotionBrandByIdApiArg
        >({
            query: (queryArg) => ({
                url: `/dictionaries/promotion-brands/${queryArg.id}`,
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    dispatch(setSettingData({property: "promotionBrand", value: data}));
                } catch (error) {
                    console.error(error);
                }
            },
        }),
        updatePromotionBrand: build.mutation<
            UpdatePromotionBrandApiResponse,
            UpdatePromotionBrandApiArg
        >({
            query: (queryArg) => ({
                url: `/dictionaries/promotion-brands/${queryArg.id}`,
                method: "PUT",
                body: queryArg.brandDto,
            }),
            invalidatesTags: [cacheTags.PROMOTION_BRANDS],
        }),
        deletePromotionBrand: build.mutation<
            DeletePromotionBrandApiResponse,
            DeletePromotionBrandApiArg
        >({
            query: (queryArg) => ({
                url: `/dictionaries/promotion-brands/${queryArg.id}`,
                method: "DELETE",
            }),
            invalidatesTags: [cacheTags.PROMOTION_BRANDS],
        }),
        getAllPromotions: build.query<
            GetAllPromotionsApiResponse,
            GetAllPromotionsApiArg
        >({
            query: () => ({url: `/promotions`}),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: cacheTags.PROMOTIONS, id})),
                        cacheTags.PROMOTIONS,
                    ]
                    : [cacheTags.PROMOTIONS],
        }),
        createPromotion: build.mutation<
            CreatePromotionApiResponse,
            CreatePromotionApiArg
        >({
            query: (queryArg) => ({
                url: `/promotions`,
                method: "POST",
                body: queryArg.promotionRequest,
            }),
            invalidatesTags: [cacheTags.PROMOTIONS],
        }),
        findPromotionGroupCategories: build.query<
            FindPromotionGroupCategoriesApiResponse,
            FindPromotionGroupCategoriesApiArg
        >({
            query: (queryArg) => ({
                url: `/dictionaries/promotion-group-categories`,
                params: {
                    page: queryArg.page,
                    size: queryArg.size,
                    sort: queryArg.sort,
                },
            }),
            providesTags: (result) =>
                result?.content
                    ? [
                        ...result?.content?.map(({id}) => ({type: cacheTags.PROMOTION_GROUP_CATEGORIES, id})),
                        cacheTags.PROMOTION_GROUP_CATEGORIES,
                    ]
                    : [cacheTags.PROMOTION_GROUP_CATEGORIES],
        }),
        createPromotionGroupCategory: build.mutation<
            CreatePromotionGroupCategoryApiResponse,
            CreatePromotionGroupCategoryApiArg
        >({
            query: (queryArg) => ({
                url: `/dictionaries/promotion-group-categories`,
                method: "POST",
                body: queryArg.groupCategoryDto,
            }),
            invalidatesTags: [cacheTags.PROMOTION_GROUP_CATEGORIES],
        }),
        findPromotionCategories: build.query<
            FindPromotionCategoriesApiResponse,
            FindPromotionCategoriesApiArg
        >({
            query: (queryArg) => ({
                url: `/dictionaries/promotion-categories`,
                params: {
                    page: queryArg.page,
                    size: queryArg.size,
                    sort: queryArg.sort,
                },
            }),
            providesTags: (result) =>
                result?.content
                    ? [
                        ...result?.content?.map(({id}) => ({type: cacheTags.PROMOTION_CATEGORIES, id})),
                        cacheTags.PROMOTION_CATEGORIES,
                    ]
                    : [cacheTags.PROMOTION_CATEGORIES],
        }),
        createPromotionCategory: build.mutation<
            CreatePromotionCategoryApiResponse,
            CreatePromotionCategoryApiArg
        >({
            query: (queryArg) => ({
                url: `/dictionaries/promotion-categories`,
                method: "POST",
                body: queryArg.categoryDto,
            }),
            invalidatesTags: [cacheTags.PROMOTION_CATEGORIES],
        }),
        findPromotionBrands: build.query<
            FindPromotionBrandsApiResponse,
            FindPromotionBrandsApiArg
        >({
            query: (queryArg) => ({
                url: `/dictionaries/promotion-brands`,
                params: {
                    page: queryArg.page,
                    size: queryArg.size,
                    sort: queryArg.sort,
                },
            }),
            providesTags: (result) =>
                result?.content
                    ? [
                        ...result?.content?.map(({id}) => ({type: cacheTags.PROMOTION_BRANDS, id})),
                        cacheTags.PROMOTION_BRANDS,
                    ]
                    : [cacheTags.PROMOTION_BRANDS],
        }),
        createPromotionBrand: build.mutation<
            CreatePromotionBrandApiResponse,
            CreatePromotionBrandApiArg
        >({
            query: (queryArg) => ({
                url: `/dictionaries/promotion-brands`,
                method: "POST",
                body: queryArg.brandDto,
            }),
            invalidatesTags: [cacheTags.PROMOTION_BRANDS],
        })
    }),
    overrideExisting: false,
});
export {injectedRtkApi as enhancedApi};
export type FindWtbByIdApiResponse = /** status 200 OK */ WtbResponse;
export type FindWtbByIdApiArg = {
    id: string;
};
export type EditWtbApiResponse = /** status 200 OK */ WtbResponse;
export type EditWtbApiArg = {
    id: string;
    wtbRequest: WtbRequest;
};
export type DeleteWtbApiResponse = unknown;
export type DeleteWtbApiArg = {
    id: string;
};
export type GetAllWtbsApiResponse = /** status 200 OK */ WtbResponse[];
export type GetAllWtbsApiArg = void;
export type CreateWtbApiResponse = /** status 200 OK */ WtbResponse;
export type CreateWtbApiArg = {
    wtbRequest: WtbRequest;
};
export type FindDropByIdApiResponse = /** status 200 OK */ DropDetailsResponse;
export type FindDropByIdApiArg = {
    id: string;
};
export type EditDropApiResponse = /** status 200 OK */ DropResponse;
export type EditDropApiArg = {
    id: string;
    dropRequest: DropRequest;
};
export type DisableDropApiResponse = unknown;
export type DisableDropApiArg = {
    id: string;
};
export type FindBannerByIdApiResponse = /** status 200 OK */ BannerResponse;
export type FindBannerByIdApiArg = {
    id: string;
};
export type EditBannerApiResponse = /** status 200 OK */ BannerResponse;
export type EditBannerApiArg = {
    id: string;
    bannerRequest: BannerRequest;
};
export type DeleteBannerApiResponse = unknown;
export type DeleteBannerApiArg = {
    id: string;
};
export type FindAllWalletProductsApiResponse =
/** status 200 OK */ PageWalletProductResponse;
export type FindAllWalletProductsApiArg = {
    queryText?: string;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
    sort?: string[];
};
export type SendProposalAboutWalletProductApiResponse = unknown;
export type SendProposalAboutWalletProductApiArg = {
    id: string;
};
export type FindAllProductsApiResponse =
/** status 200 OK */ PageSearchProductQueryResult;
export type FindAllProductsApiArg = {
    queryText?: string;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
    sort?: string[];
};
export type CreateProductApiResponse = unknown;
export type CreateProductApiArg = {
    createProductRequest: CreateProductRequest;
};
export type SendCustomNotificationApiResponse = /** status 200 OK */ string;
export type SendCustomNotificationApiArg = {
    customNotificationRequest: CustomNotificationRequest;
};
export type FindAllDropsApiResponse = /** status 200 OK */ DropResponse[];
export type FindAllDropsApiArg = void;
export type CreateDropApiResponse = /** status 200 OK */ DropResponse;
export type CreateDropApiArg = {
    dropRequest: DropRequest;
};
export type GetAllBannersApiResponse = /** status 200 OK */ BannerResponse[];
export type GetAllBannersApiArg = {
    genderType: "MALE" | "FEMALE" | "DEFAULT" | "GRAIL_POINT" | "UNISEX";
    bannerType?:
        | "AFFILIATE_1"
        | "AFFILIATE_1_2"
        | "AFFILIATE_2"
        | "AFFILIATE_2_2"
        | "AFFILIATE_3"
        | "AFFILIATE_3_2"
        | "AFFILIATE_3_3"
        | "AFFILIATE_3_4"
        | "AFFILIATE_3_5"
        | "AFFILIATE_7"
        | "AFFILIATE_7_2"
        | "AFFILIATE_7_3"
        | "AFFILIATE_7_4"
        | "AFFILIATE_7_5"
        | "AFFILIATE_8"
        | "AFFILIATE_8_2"
        | "AFFILIATE_8_3"
        | "AFFILIATE_8_4"
        | "AFFILIATE_8_5"
        | "AFFILIATE_8_6"
        | "AFFILIATE_8_7"
        | "AFFILIATE_8_8"
        | "AFFILIATE_8_9"
        | "AFFILIATE_8_10"
        | "AFFILIATE_8_11"
        | "AFFILIATE_8_12"
        | "AFFILIATE_8_13"
        | "AFFILIATE_8_14"
        | "AFFILIATE_8_15"
        | "AFFILIATE_9"
        | "AFFILIATE_9_2"
        | "AFFILIATE_9_3"
        | "AFFILIATE_9_4"
        | "AFFILIATE_9_5"
        | "BARGAIN_1"
        | "BARGAIN_4"
        | "BARGAIN_2"
        | "BARGAIN_2_2"
        | "BARGAIN_2_3"
        | "BARGAIN_2_4"
        | "BARGAIN_2_5"
        | "BARGAIN_3"
        | "BARGAIN_3_2"
        | "BARGAIN_3_3"
        | "BARGAIN_3_4"
        | "BARGAIN_3_5"
        | "BARGAIN_3_6"
        | "BARGAIN_3_7"
        | "BARGAIN_3_8"
        | "BARGAIN_3_9"
        | "BARGAIN_3_10"
        | "BARGAIN_7"
        | "BARGAIN_7_2"
        | "BARGAIN_7_3"
        | "BARGAIN_7_4"
        | "BARGAIN_7_5"
        | "BARGAIN_8"
        | "BARGAIN_8_2"
        | "BARGAIN_8_3"
        | "BARGAIN_8_4"
        | "BARGAIN_8_5"
        | "BARGAIN_9"
        | "BARGAIN_9_2"
        | "BARGAIN_9_3"
        | "BARGAIN_9_4"
        | "BARGAIN_9_5"
        | "GRAIL_SHOP_1"
        | "GRAIL_SHOP_2"
        | "GRAIL_SHOP_3"
        | "GRAIL_SHOP_4"
        | "GRAIL_SHOP_5"
        | "GRAIL_SHOP_6"
        | "PROMOTION_1";
};
export type CreateBannerApiResponse = /** status 200 OK */ BannerResponse;
export type CreateBannerApiArg = {
    bannerRequest: BannerRequest;
};
export type FindProductByIdApiResponse = /** status 200 OK */ ProductResponse;
export type FindProductByIdApiArg = {
    id: string;
    variantId?: string;
};
export type DeleteProductApiResponse = unknown;
export type DeleteProductApiArg = {
    id: string;
};
export type WtbResponse = {
    id?: string;
    name?: string;
    imageUrl?: string;
    priority?: number;
};
export type WtbRequest = {
    name?: string;
    imageUrl?: string;
    priority?: number;
};
export type DropShopDto = {
    date?: string;
    imageUrl?: string;
    url?: string;
};
export type DropDetailsResponse = {
    id?: string;
    title?: string;
    description?: string;
    price?: string;
    resellPrice?: string;
    currency?: "PLN" | "EUR" | "USD";
    publishedDate?: string;
    startDate?: string;
    confirmedStartDate?: boolean;
    expiredDate?: string;
    imageUrl?: string;
    referralLink?: string;
    sku?: string;
    colorWay?: string;
    shops?: DropShopDto[];
    votedCount?: number;
    voted?: boolean;
    glow?: boolean;
    enabled?: boolean;
};
export type DropResponse = {
    id?: string;
    title?: string;
    description?: string;
    price?: string;
    resellPrice?: string;
    currency?: "PLN" | "EUR" | "USD";
    publishedDate?: string;
    startDate?: string;
    confirmedStartDate?: boolean;
    expiredDate?: string;
    imageUrl?: string;
    referralLink?: string;
    sku?: string;
    colorWay?: string;
    priority?: number;
    shops?: DropShopDto[];
    glow?: boolean;
    enabled?: boolean;
};
export type DropRequest = {
    title?: string;
    description?: string;
    price?: string;
    resellPrice?: string;
    currency?: "PLN" | "EUR" | "USD";
    publishedDate?: string;
    startDate?: string;
    confirmedStartDate?: boolean;
    expiredDate?: string;
    imageUrl?: string;
    referralLink?: string;
    sku?: string;
    colorWay?: string;
    priority?: number;
    shops?: DropShopDto[];
    glow?: boolean;
    enabled?: boolean;
};
export type BannerResponse = {
    id?: string;
    name?: string;
    type?:
        | "AFFILIATE_1"
        | "AFFILIATE_1_2"
        | "AFFILIATE_2"
        | "AFFILIATE_2_2"
        | "AFFILIATE_3"
        | "AFFILIATE_3_2"
        | "AFFILIATE_3_3"
        | "AFFILIATE_3_4"
        | "AFFILIATE_3_5"
        | "AFFILIATE_7"
        | "AFFILIATE_7_2"
        | "AFFILIATE_7_3"
        | "AFFILIATE_7_4"
        | "AFFILIATE_7_5"
        | "AFFILIATE_8"
        | "AFFILIATE_8_2"
        | "AFFILIATE_8_3"
        | "AFFILIATE_8_4"
        | "AFFILIATE_8_5"
        | "AFFILIATE_8_6"
        | "AFFILIATE_8_7"
        | "AFFILIATE_8_8"
        | "AFFILIATE_8_9"
        | "AFFILIATE_8_10"
        | "AFFILIATE_8_11"
        | "AFFILIATE_8_12"
        | "AFFILIATE_8_13"
        | "AFFILIATE_8_14"
        | "AFFILIATE_8_15"
        | "AFFILIATE_9"
        | "AFFILIATE_9_2"
        | "AFFILIATE_9_3"
        | "AFFILIATE_9_4"
        | "AFFILIATE_9_5"
        | "BARGAIN_1"
        | "BARGAIN_4"
        | "BARGAIN_2"
        | "BARGAIN_2_2"
        | "BARGAIN_2_3"
        | "BARGAIN_2_4"
        | "BARGAIN_2_5"
        | "BARGAIN_3"
        | "BARGAIN_3_2"
        | "BARGAIN_3_3"
        | "BARGAIN_3_4"
        | "BARGAIN_3_5"
        | "BARGAIN_3_6"
        | "BARGAIN_3_7"
        | "BARGAIN_3_8"
        | "BARGAIN_3_9"
        | "BARGAIN_3_10"
        | "BARGAIN_7"
        | "BARGAIN_7_2"
        | "BARGAIN_7_3"
        | "BARGAIN_7_4"
        | "BARGAIN_7_5"
        | "BARGAIN_8"
        | "BARGAIN_8_2"
        | "BARGAIN_8_3"
        | "BARGAIN_8_4"
        | "BARGAIN_8_5"
        | "BARGAIN_9"
        | "BARGAIN_9_2"
        | "BARGAIN_9_3"
        | "BARGAIN_9_4"
        | "BARGAIN_9_5"
        | "GRAIL_SHOP_1"
        | "GRAIL_SHOP_2"
        | "GRAIL_SHOP_3"
        | "GRAIL_SHOP_4"
        | "GRAIL_SHOP_5"
        | "GRAIL_SHOP_6"
        | "PROMOTION_1";
    imageUrl?: string;
    referralLink?: string;
    price?: number;
    discountedPrice?: number;
    priority?: number;
    title?: string;
    subtitle?: string;
    enabled?: boolean;
    shopCategorySlug?: string;
    shopProductId?: string;
};
export type BannerRequest = {
    name?: string;
    type?:
        | "AFFILIATE_1"
        | "AFFILIATE_1_2"
        | "AFFILIATE_2"
        | "AFFILIATE_2_2"
        | "AFFILIATE_3"
        | "AFFILIATE_3_2"
        | "AFFILIATE_3_3"
        | "AFFILIATE_3_4"
        | "AFFILIATE_3_5"
        | "AFFILIATE_7"
        | "AFFILIATE_7_2"
        | "AFFILIATE_7_3"
        | "AFFILIATE_7_4"
        | "AFFILIATE_7_5"
        | "AFFILIATE_8"
        | "AFFILIATE_8_2"
        | "AFFILIATE_8_3"
        | "AFFILIATE_8_4"
        | "AFFILIATE_8_5"
        | "AFFILIATE_8_6"
        | "AFFILIATE_8_7"
        | "AFFILIATE_8_8"
        | "AFFILIATE_8_9"
        | "AFFILIATE_8_10"
        | "AFFILIATE_8_11"
        | "AFFILIATE_8_12"
        | "AFFILIATE_8_13"
        | "AFFILIATE_8_14"
        | "AFFILIATE_8_15"
        | "AFFILIATE_9"
        | "AFFILIATE_9_2"
        | "AFFILIATE_9_3"
        | "AFFILIATE_9_4"
        | "AFFILIATE_9_5"
        | "BARGAIN_1"
        | "BARGAIN_4"
        | "BARGAIN_2"
        | "BARGAIN_2_2"
        | "BARGAIN_2_3"
        | "BARGAIN_2_4"
        | "BARGAIN_2_5"
        | "BARGAIN_3"
        | "BARGAIN_3_2"
        | "BARGAIN_3_3"
        | "BARGAIN_3_4"
        | "BARGAIN_3_5"
        | "BARGAIN_3_6"
        | "BARGAIN_3_7"
        | "BARGAIN_3_8"
        | "BARGAIN_3_9"
        | "BARGAIN_3_10"
        | "BARGAIN_7"
        | "BARGAIN_7_2"
        | "BARGAIN_7_3"
        | "BARGAIN_7_4"
        | "BARGAIN_7_5"
        | "BARGAIN_8"
        | "BARGAIN_8_2"
        | "BARGAIN_8_3"
        | "BARGAIN_8_4"
        | "BARGAIN_8_5"
        | "BARGAIN_9"
        | "BARGAIN_9_2"
        | "BARGAIN_9_3"
        | "BARGAIN_9_4"
        | "BARGAIN_9_5"
        | "GRAIL_SHOP_1"
        | "GRAIL_SHOP_2"
        | "GRAIL_SHOP_3"
        | "GRAIL_SHOP_4"
        | "GRAIL_SHOP_5"
        | "GRAIL_SHOP_6"
        | "PROMOTION_1";
    imageUrl?: string;
    referralLink?: string;
    price?: number;
    discountedPrice?: number;
    genderType?: "MALE" | "FEMALE" | "DEFAULT" | "GRAIL_POINT" | "UNISEX";
    priority?: number;
    title?: string;
    subtitle?: string;
    enabled?: boolean;
    shopCategorySlug?: string;
    shopProductId?: string;
};
export type WalletProductResponse = {
    id?: string;
    userId?: string;
    productId?: string;
    variantId?: string;
    price?: number;
    notificationEnabled?: boolean;
    productName?: string;
    productSize?: string;
    lastSendProposalDate?: string;
};
export type SortObject = {
    empty?: boolean;
    unsorted?: boolean;
    sorted?: boolean;
};
export type PageableObject = {
    offset?: number;
    sort?: SortObject;
    paged?: boolean;
    unpaged?: boolean;
    pageNumber?: number;
    pageSize?: number;
};
export type PageWalletProductResponse = {
    totalPages?: number;
    totalElements?: number;
    size?: number;
    content?: WalletProductResponse[];
    number?: number;
    sort?: SortObject;
    numberOfElements?: number;
    pageable?: PageableObject;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type SearchProductQueryResult = {
    id?: string;
    name?: string;
    imageUrl?: string;
};
export type PageSearchProductQueryResult = {
    totalPages?: number;
    totalElements?: number;
    size?: number;
    content?: SearchProductQueryResult[];
    number?: number;
    sort?: SortObject;
    numberOfElements?: number;
    pageable?: PageableObject;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type CreateProductRequest = {
    source?: "STOCKX";
    url?: string;
};
export type CustomNotificationRequest = {
    title?: string;
    subTitle?: string;
    description?: string;
    imageUrl?: string;
    data?: {
        [key: string]: string;
    };
};
export type ProductImageResponse = {
    imageUrl?: string;
    imageOrder?: number;
};
export type ProductVariantResponse = {
    id?: string;
    shoeSize?: string;
    sizeSortOrder?: number;
};
export type ProductResponse = {
    id?: string;
    brand?: string;
    colorway?: string;
    name?: string;
    primaryCategory?: string;
    secondaryCategory?: string;
    productCategory?: string;
    releaseDate?: string;
    retailPrice?: number;
    currency?: "PLN" | "EUR" | "USD";
    shoe?: string;
    shortDescription?: string;
    title?: string;
    sizeLocale?: string;
    year?: number;
    imageUrl?: string;
    images?: ProductImageResponse[];
    variants?: ProductVariantResponse[];
    pricePln?: number;
};
export type FindPromotionByIdApiResponse =
/** status 200 OK */ PromotionResponse;
export type FindPromotionByIdApiArg = {
    id: string;
};
export type EditPromotionApiResponse = /** status 200 OK */ PromotionResponse;
export type EditPromotionApiArg = {
    id: string;
    promotionRequest: PromotionRequest;
};
export type DeletePromotionApiResponse = unknown;
export type DeletePromotionApiArg = {
    id: string;
};
export type FindPromotionGroupCategoryByIdApiResponse =
/** status 200 OK */ GroupCategoryDto;
export type FindPromotionGroupCategoryByIdApiArg = {
    id: string;
};
export type UpdatePromotionGroupCategoryApiResponse =
/** status 200 OK */ GroupCategoryDto;
export type UpdatePromotionGroupCategoryApiArg = {
    id: string;
    groupCategoryDto: GroupCategoryDto;
};
export type DeletePromotionGroupCategoryApiResponse = unknown;
export type DeletePromotionGroupCategoryApiArg = {
    id: string;
};
export type FindPromotionCategoryByIdApiResponse =
/** status 200 OK */ CategoryDto;
export type FindPromotionCategoryByIdApiArg = {
    id: string;
};
export type UpdatePromotionCategoryApiResponse =
/** status 200 OK */ CategoryDto;
export type UpdatePromotionCategoryApiArg = {
    id: string;
    categoryDto: CategoryDto;
};
export type DeletePromotionCategoryApiResponse = unknown;
export type DeletePromotionCategoryApiArg = {
    id: string;
};
export type FindPromotionBrandByIdApiResponse = /** status 200 OK */ BrandDto;
export type FindPromotionBrandByIdApiArg = {
    id: string;
};
export type UpdatePromotionBrandApiResponse = /** status 200 OK */ BrandDto;
export type UpdatePromotionBrandApiArg = {
    id: string;
    brandDto: BrandDto;
};
export type DeletePromotionBrandApiResponse = unknown;
export type DeletePromotionBrandApiArg = {
    id: string;
};
export type GetAllPromotionsApiResponse =
/** status 200 OK */ PromotionResponse[];
export type GetAllPromotionsApiArg = void;
export type CreatePromotionApiResponse = /** status 200 OK */ PromotionResponse;
export type CreatePromotionApiArg = {
    promotionRequest: PromotionRequest;
};
export type FindPromotionGroupCategoriesApiResponse =
/** status 200 OK */ PageGroupCategoryDto;
export type FindPromotionGroupCategoriesApiArg = {
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
    sort?: string[];
};
export type CreatePromotionGroupCategoryApiResponse =
/** status 200 OK */ GroupCategoryDto;
export type CreatePromotionGroupCategoryApiArg = {
    groupCategoryDto: GroupCategoryDto;
};
export type FindPromotionCategoriesApiResponse =
/** status 200 OK */ PageCategoryDto;
export type FindPromotionCategoriesApiArg = {
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
    sort?: string[];
};
export type CreatePromotionCategoryApiResponse =
/** status 200 OK */ CategoryDto;
export type CreatePromotionCategoryApiArg = {
    categoryDto: CategoryDto;
};
export type FindPromotionBrandsApiResponse = /** status 200 OK */ PageBrandDto;
export type FindPromotionBrandsApiArg = {
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
    sort?: string[];
};
export type CreatePromotionBrandApiResponse = /** status 200 OK */ BrandDto;
export type CreatePromotionBrandApiArg = {
    brandDto: BrandDto;
};
export type PromotionResponse = {
    id?: string;
    name?: string;
    imageUrl?: string;
    priority?: number;
    genderType?: "MALE" | "FEMALE" | "DEFAULT" | "GRAIL_POINT" | "UNISEX";
    categoryId?: string;
    brandId?: string;
    price?: number;
    enabled?: boolean;
    referralLink?: string;
    shopCategorySlug?: string;
    shopProductId?: string;
};
export type PromotionRequest = {
    name?: string;
    imageUrl?: string;
    priority?: number;
    genderType?: "MALE" | "FEMALE" | "DEFAULT" | "GRAIL_POINT" | "UNISEX";
    categoryId?: string;
    brandId?: string;
    price?: number;
    enabled?: boolean;
    referralLink?: string;
    shopCategorySlug?: string;
    shopProductId?: string;
};
export type GroupCategoryDto = {
    id?: string;
    name: string;
};
export type CategoryDto = {
    id?: string;
    groupCategoryId?: string;
    name: string;
};
export type BrandDto = {
    id?: string;
    name: string;
    imageUrl: string;
    priority?: number;
};
export type PageGroupCategoryDto = {
    totalPages?: number;
    totalElements?: number;
    first?: boolean;
    last?: boolean;
    size?: number;
    content?: GroupCategoryDto[];
    number?: number;
    sort?: SortObject;
    numberOfElements?: number;
    pageable?: PageableObject;
    empty?: boolean;
};
export type PageCategoryDto = {
    totalPages?: number;
    totalElements?: number;
    first?: boolean;
    last?: boolean;
    size?: number;
    content?: CategoryDto[];
    number?: number;
    sort?: SortObject;
    numberOfElements?: number;
    pageable?: PageableObject;
    empty?: boolean;
};
export type PageBrandDto = {
    totalPages?: number;
    totalElements?: number;
    first?: boolean;
    last?: boolean;
    size?: number;
    content?: BrandDto[];
    number?: number;
    sort?: SortObject;
    numberOfElements?: number;
    pageable?: PageableObject;
    empty?: boolean;
};
export const {
    useFindWtbByIdQuery,
    useEditWtbMutation,
    useDeleteWtbMutation,
    useGetAllWtbsQuery,
    useCreateWtbMutation,
    useFindDropByIdQuery,
    useEditDropMutation,
    useDisableDropMutation,
    useFindBannerByIdQuery,
    useEditBannerMutation,
    useDeleteBannerMutation,
    useFindAllWalletProductsQuery,
    useSendProposalAboutWalletProductMutation,
    useFindAllProductsQuery,
    useCreateProductMutation,
    useSendCustomNotificationMutation,
    useFindAllDropsQuery,
    useCreateDropMutation,
    useGetAllBannersQuery,
    useCreateBannerMutation,
    useFindProductByIdQuery,
    useDeleteProductMutation,
    useFindPromotionByIdQuery,
    useEditPromotionMutation,
    useDeletePromotionMutation,
    useFindPromotionGroupCategoryByIdQuery,
    useUpdatePromotionGroupCategoryMutation,
    useDeletePromotionGroupCategoryMutation,
    useFindPromotionCategoryByIdQuery,
    useUpdatePromotionCategoryMutation,
    useDeletePromotionCategoryMutation,
    useFindPromotionBrandByIdQuery,
    useUpdatePromotionBrandMutation,
    useDeletePromotionBrandMutation,
    useGetAllPromotionsQuery,
    useCreatePromotionMutation,
    useFindPromotionGroupCategoriesQuery,
    useCreatePromotionGroupCategoryMutation,
    useFindPromotionCategoriesQuery,
    useCreatePromotionCategoryMutation,
    useFindPromotionBrandsQuery,
    useCreatePromotionBrandMutation,
} = injectedRtkApi;
